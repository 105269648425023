@import './normalize.css';

/* :root {
  * {
    text-rendering: geometricPrecision;
  }
} */

body {
  margin: 0;

  * {
    -webkit-tap-highlight-color: transparent;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  display: block;
}

[class*='fs-container'] {
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  padding: 0 !important;
}

iframe {
  background: #f5f5f5;
  border: none;
  display: flex;
  width: 100%;
}

.drawer-base-config {
  position: relative;

  z-index: 3000 !important;

  #navbar {
    z-index: 3000 !important;
  }

  #drawer-content {
    z-index: 3000 !important;
  }
  ._default-overlay {
    z-index: 2000 !important;
  }
  #drawer-slot-id {
    padding: 0 !important;
  }
}

/* prettier-ignore */
#drawer-slot-id > :last-child {
  position: absolute;
}

#dcl-upload-form {
  display: none;
}

.no-underline {
  text-decoration: none !important;
}
